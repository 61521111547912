nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    position: fixed;
    width: 100%;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 45px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06); 
}

#hide-logo{
    display: none !important;
}

.hide-nav{
    background: transparent !important;
    box-shadow: none !important;
}

#big-logo{
    width: 200px;
    cursor: pointer;
}

#small-logo{
    width: 50px;
    cursor: pointer;
}

#wave-navbar{
    display: flex;
    align-items: center;
    justify-content: center;

    /* z-index:2; */
}


#wave-navbar li{
    list-style: none;
    padding: 0 20px;
    position: relative;
}

#wave-navbar li a{
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
}

#wave-navbar li a:hover,
#wave-navbar li a.activate{
    color: var(--primary-color);
}

#wave-navbar li a:hover::after,
#wave-navbar li a.activate::after{
   content: '';
   width: 30%;
   height: 2px;
   background: var(--primary-color);
   position: absolute;
   bottom: -4px;
   left: 20px;
}

#mobile{
    display: none;
}


.mobile-logs{
    display: none;
}
.mobile-maps{
    display: none;
}

.mobile-call-email{
    display: flex;
}

.mobile-things{
    display: none;
}

.mobile-side-logo{
    display: none;
}
@media screen and (max-width: 991px) {
    .mobile-side-logo{
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .mobile-call-email{
        display: none !important;
    }
    .mobile-side-logo img{
        width: 70%;
    }
    
    .mobile-things{
        display: flex;
        gap: 30px;
    }
    .mobile-logs{
        display: flex;
        /* justify-content: space-evenly; */
        gap: 30px;
        margin-left: 300px;
    }

    .mobile-maps{
        display: block;
    }
    #wave-navbar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 0px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background: white;
        /* border: 1px solid black; */
        /* box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1); */
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }
    
    #wave-navbar.active{
        right: 0px;
    }

    #wave-navbar li{
        margin-bottom: 30px;
    }

    #mobile{
        display: block;
    }
}

.iframe-maps{
    width: 100%;
    height: 140%;
}

.abc{
    display: flex;
    gap: 20px;
    align-items: center;
}

/*  */

.overlay {
    position: fixed;
    top: 60px; /* adjust according to your navbar height */
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    filter: blur(5px);
    z-index: 999; /* Lower than the navbar but higher than main content */
  }
  
  .main-content {
    padding-top: 60px; /* adjust according to your navbar height */
    /* ... (other main-content styles you might have) ... */
  }



