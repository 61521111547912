.box{
   display: flex;
   justify-content: center;
   align-items: center;
  height: 100vh;
  width: 100%;
    background: whitesmoke;
  }

.main-load{
  width: 10%;
}

  .capsule-body{
    margin: 40px auto;
    height: 300px;
    width: 120px;
    background-color: rgba(235,235,235,0.8); 
    border-radius: 70px;
    transform: rotate(45deg);
    z-index: 1;
    box-shadow: 5px 0px 10px;
    animation: spinme 2s infinite;
    border: 1px solid #2E3B52;
  }
  
  .capsule-upper{
    height: 180px;
    width: 120px;
    border-radius: 70px;
    background-color: var(--primary-color);
    z-index: 2;
    opacity : 1;
  }
  
  .upper-highlight{
    position: absolute;
    margin: 40% 10%;
    height: 85px;
    width: 20px;
    background-color: #dfe2ec;
    opacity:0.9;
    border-radius: 20px;
  }
  
  .lower-highlight{
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #dfe2ec;
    margin: 50% 10%;
  }
  
  .base-shadow{
    position: absolute;
    background-color: #9c869d;
    height: 200px;
    width: 20px;
    margin:-105% 84%;
    border-radius: 10px;
    filter: opacity(0.45); 
  }
  
  @keyframes spinme{
  0%{
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(405deg);
  }
  
  } 